import { useEffect, useState } from "react";
import { Card, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { getExerciseUrl } from "../../firebase";

const WorkoutBox = (props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  useEffect(() => {
    getExerciseUrl(`${props.Workout.Thumbnail}`).then((res) => {
      setThumbnailUrl(res);
    });
  }, [props]);
  const navigate = useNavigate();

  const getWorkoutLength = () => {
    let minutes = props.Workout.Sets * props.Workout.Exercises.length
    return minutes
  }

  return (
    <Card style={{ margin: 10 }} className={"grow"}>
      <Card.Img
        variant="top"
        src={thumbnailUrl}
        style={{ maxHeight: "250px", objectFit: "cover" }}
      />
      <div className="m-2">
        <h2 className="pageTitle title-margin text-truncate">
          {props.Workout.Name}
        </h2>
        <h3 className=" text-center title-margin text-truncate">
        {getWorkoutLength()} minutes
        </h3>
        
      </div>
    </Card>
  );
};

export default WorkoutBox;
