import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessons,
  getAllStrandsNew,
  getAllSubcategoriesNew,
  uploadAllCategoriesNew,
  uploadAllLessonsNew,
  uploadAllSubCategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import StructuredLessonItem from "../../components/StructuredLessonItem";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";

function UpdatedStrandsPage() {
  const [strandsText, setStrandsText] = useState("");
  const [strands, setStrands] = useState([]);
  const { data, data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Strands";
    if ("UserName" in data) {
      switch (parseInt(data2.Year)) {
        case 0:
          setStrandsText("Junior & Senior");
          break;
        case 1:
          setStrandsText("1st & 2nd");
          break;
        case 2:
          setStrandsText("3rd & 4th");
          break;
        case 3:
          setStrandsText("5th & 6th");
          break;
        default:
          setStrandsText("ERROR");
      }

      getAllStrandsNew().then((res) => {
        if (res.length > 0) {
          setStrands(
            res.sort((a, b) =>
              a.StrandNumber > b.StrandNumber
                ? 1
                : a.StrandNumber < b.StrandNumber
                ? -1
                : 0
            )
          );
        }
      });
    }
  }, [data, data2]);

  const goToStrand = (guid) => {
    navigate(guid)
  }
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">PE Strands for {strandsText}</h1>
          <br />
          <Row className="structured-lesson-list-container ">
            {strands?.map((strand, i) => {
              if (strand.Active) {
                return (
                  <Col md={4} key={i}>
                    <StrandCategoryBox
                      title={strand.Title}
                      icon={strand.ImageUri}
                      color={strand.ColourName}
                      content={strand.Title}
                      click={() => goToStrand(strand.Guid)}
                      // test={true}
                    ></StrandCategoryBox>
                  </Col>
                );
              }
              return "";
            })}
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default UpdatedStrandsPage;
