import { useContext, useEffect, useState } from "react";
import { Button, Container, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getAllCategoriesNew,
  getAllLessons,
  getAllStrandsNew,
  getAllSubcategoriesNew,
  getImageUrl,
  getLessonNew,
  lessonAnalytics,
  uploadAllCategoriesNew,
  uploadAllLessonsNew,
  uploadAllSubCategoriesNew,
} from "../../firebase";
import CategoryBox from "../../components/CategoryBox";
import SearchBarLink from "../../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../../firebase";
import { UserContext } from "../../providers/UserProvider";
import { Link } from "react-router-dom";

function UpdatedLessonPage() {
  const [lesson, setLesson] = useState({});
  const { data, data2 } = useContext(UserContext);
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Lessons";
    if ("UserName" in data) {
      getLessonNew(id).then((data2) => {
        if (data2) {
          setLesson(data2);
          lessonAnalytics(id, data2.Title, data2.Year, data.Guid, data.UserName);
        }
      });
    }
  }, [data, data2]);

  const loadResource = async (name) => {
    let url = await getImageUrl("resources/" + name);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    document.body.appendChild(link);
    link.click();
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <h1 className="pageTitle title-margin">{lesson.Title}</h1>
        <br />
        <div className="structured-lesson-video-box">
          <div className=" structured-lesson-video-bordered ">
            <div className=" video-responsive-structured">
              {"UrlEmbeded" in lesson ? (
                <iframe
                  src={lesson.UrlEmbeded}
                  allowFullScreen
                  title="Embedded youtube"
                />
              ) : (
                <div width="853" height="480"></div>
              )}
            </div>
          </div>
        </div>
        <Container className="content-item-container content-item-container-variant-1 ">
          {/* <br /> */}

          <br />

          <div className=" px-3">
            <h2 className="structured-content-heading">{lesson.Title}</h2>
            <br />
            <div className="structured-content-text">{lesson.Description}</div>
            <br />
            {/* <h2 className="structured-content-heading">Learning Objective</h2>
            <br />
            <div className="structured-content-text">
              Learning how to jump into a hula hoop in a race formation.
            </div> */}
            {/* <br /> */}
            <h2 className="structured-content-heading">What will I need?</h2>
            <br />
            <ul>
              {lesson.Equipment
                ? lesson.Equipment.split(" & ").map((value, i) => {
                    return (
                      <li key={i} className="structured-content-text">
                        {value}
                      </li>
                    );
                  })
                : ""}
            </ul>
            <br />
            {"Resources" in lesson && lesson.Resources !== "" ? (
              <div>
                <h2 className="structured-content-heading">Resources</h2>
                <br />
                <ul>
                  {lesson.Resources
                    ? lesson.Resources.split("&").map((value, i) => {
                        return (
                          <li
                            className="grow headingColour"
                            key={i}
                            onClick={() => loadResource(value)}
                          >
                            <a role="button">{value}</a>
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <br />
              </div>
            ) : (
              ""
            )}
            <br />
            <Link to={`add`} className="text-link d-grid">
              <button type="button" className={`custom-card-button m-0`}>
                Add To Schedule
              </button>
            </Link>
            <br />
            {data.Guid === "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" ? (
              <Link
                to={`/content-management/lesson/${id}`}
                className="text-link d-grid"
              >
                <button
                  type="button"
                  className={`custom-card-button custom-card-button-variant-2 m-0`}
                >
                  Edit
                </button>
              </Link>
            ) : (
              <div></div>
            )}
          </div>
          <br />
        </Container>
        <br />
      </div>
    </Container>
  );
}

export default UpdatedLessonPage;
