import { useEffect } from "react";
import { useState } from "react";
import { Container, Button, Card, Row, Modal, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import {
  getUserData,
  removeStudentFromClass,
  resetStudentPassword,
} from "../firebase";

function StudentPage() {
  const [imageUrl, setImageUrl] = useState("");
  const [student, setStudent] = useState({});

  const [password, setPassword] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [showReset, setShowReset] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseReset = () => setShowReset(false);
  const handleShowReset = () => setShowReset(true);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const navigate = useNavigate();

  let { id } = useParams();
  useEffect(() => {
    document.title = "Daly Exercise+ | Student";
    if (Object.keys(student).length === 0) {
      getUserData(id).then((data) => {
        if (data) {
          setStudent(data);
          setImageUrl(`/avatars/${data.ImageName.toLowerCase()}.png`);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeStudent = async () => {
    handleCloseDelete();
    await removeStudentFromClass(student.Guid, student.ClassId);
    navigate("/classroom");
  };

  const resetPassword = async () => {
    if (password === "") {
      setpasswordError("Please enter password");
      return;
    } else {
      setpasswordError("");
    }

    if (password.length < 6) {
      setpasswordError("Password must be greater than 6 characters");
      return;
    } else {
      setpasswordError("");
    }
    handleCloseReset();
    await resetStudentPassword(student.Guid, password);
    window.alert("Password successfully reset");
  };

  return (
    <Container className="full-page-length">
      <h1 className="pageTitle title-margin">{student.FirstName} {student.LastName}</h1>
      <hr />
      <div className="d-flex justify-content-md-center m-1">
        <Card style={{ width: "24rem" }} className="border-0 ">
          <Card.Img variant="top" src={imageUrl} />
          <Card.Title className="text-center">
            Username: {student.UserName}
          </Card.Title>
          <Button variant="primary" className="m-1 mainButton" onClick={handleShowReset}>
            Reset Password
          </Button>

          <Button variant="danger" className="m-1 button-no-colour mt-3" onClick={handleShowDelete}>
            Remove Student
          </Button>
        </Card>
      </div>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Remove Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wish to remove {student.FirstName} {student.LastName}{" "}
          from your classroom?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={removeStudent}>
            Confrim
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showReset} onHide={handleCloseReset}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Student Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
              aria-describedby="passwordHelpBlock"
            />
            <Form.Text id="passwordHelpBlock" muted>
              {passwordError}
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseReset}>
            Close
          </Button>
          <Button variant="primary" onClick={resetPassword}>
            Confrim
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default StudentPage;
