import { useContext, useEffect, useState, useRef } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import SearchBar from "../../components/SearchBar";
import UnitLessonBox from "../../components/UnitLessonBox";
import {
  getStrandCategories,
  getStrandNew,
  getSubcategories,
  getSubcategoryLessons,
  verifyInviteCode,
} from "../../firebase";
import { UserContext } from "../../providers/UserProvider";

function UpdatedCategoryPage() {
  const accordionRef = useRef(null);
  const [units, setUnits] = useState({});
  const [lessons, setLessons] = useState({});
  const [subscribed, setSubscribed] = useState(false);
  const [unorderedLessons, setUnorderedLessons] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [strand, setStrand] = useState("");
  const [loading, setLoading] = useState(true);
  const [allLessons, setAllLessons] = useState({});
  const [categories2, setCategories2] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState(0);

  const [selectedCategory, setSelectedCategory] = useState({});
  const navigate = useNavigate();

  const { data, hasSubscription, data2 } = useContext(UserContext);
  let { id } = useParams();
  useEffect(() => {
    document.title = "Daly Exercise+ | Categories";
    if ("UserName" in data) {
      getStrandNew(id).then((res) => {
        setStrand(res);

        getStrandCategories(res.Categories).then((categories) => {
          let sorted = categories.sort((a, b) => a.Order - b.Order);
          setCategories2(sorted);
          setSubCategories({});
          setSubscribed(hasSubscription);
          if (Object.keys(selectedCategory).length === 0) {
            changeCategory(sorted[0], true);
          } else {
            changeCategory(selectedCategory, true);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, data2]);

  const getFilteredLessons = (lessons) => {
    if (searchTitle === "") {
      return lessons;
    }

    let filtered = lessons?.filter((lesson) =>
      lesson.Title?.toLowerCase().includes(searchTitle.toLowerCase())
    );

    if (filtered.length === 0) {
      filtered.push({
        Title: "empty",
      });
    }

    return filtered;
  };

  const changeCategory = async (newCategory, reset = false, index = 0) => {
    setSelectedCategory(newCategory);
    setLoading(true);
    if (newCategory.SubCategories[0] in subCategories && !reset) {
    } else {
      let subCategories2 = await getSubcategories(newCategory.SubCategories);
      let lessonIds = subCategories2
        .map((subCategory) => subCategory.Lessons)
        .flat(1);
      let newLessons = await getSubcategoryLessons(lessonIds, data2.Year);
      let totalSubcategories = {};
      subCategories2.forEach((sc) => {
        let currL = {};
        sc.Lessons.forEach((l) => {
          let foundL = newLessons.find((x) => x.Guid === l);
          if (foundL !== undefined) {
            currL[l] = foundL;
          }
        });

        totalSubcategories[sc.Guid] = {
          ...sc,
          Lessons: currL,
        };
      });
      if (reset) {
        setSubCategories({
          ...totalSubcategories,
        });
      } else {
        setSubCategories({
          ...subCategories,
          ...totalSubcategories,
        });
      }
    }
    setActiveCategory(index);
    setLoading(false);
  };

  const goToLesson = (lesson) => {
    navigate(lesson.Guid);
  };

  const changeCategoryClickedMobile = (newCategory, reset, index) => {
    accordionRef.current.querySelector("button").click();
    changeCategory(newCategory, reset, index);
  };

  const defaultOpen = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];

  return (
    <Container fluid className="mx-0 px-0">
      <Row className="dashboard-body m-0 p-0">
        <Col md={2} className="px-0">
          <Container className="content-category-list">
            <br />

            <div className="show-web">
              <h2 className="pageTitle">{strand?.Title}</h2>
              <br />
              {categories2?.map((cat, i) => {
                return (
                  <div
                    key={i}
                    className={`content-category-list-item ${
                      activeCategory === i
                        ? "content-category-list-item-active"
                        : ""
                    }`}
                    onClick={() => changeCategory(cat, false, i)}
                  >
                    {cat.Title}
                  </div>
                );
              })}
            </div>
            <div className="show-mobile">
              <Accordion defaultActiveKey={[0]}>
                <Accordion.Item eventKey={0}>
                  <Accordion.Header
                    bsPrefix="accordion-title"
                    ref={accordionRef}
                  >
                    <h2 className="pageTitle">{strand?.Title}</h2>
                  </Accordion.Header>
                  <Accordion.Body>
                    {categories2?.map((cat, i) => {
                      return (
                        <div
                          key={i}
                          className={`content-category-list-item ${
                            activeCategory === i
                              ? "content-category-list-item-active"
                              : ""
                          }`}
                          onClick={() =>
                            changeCategoryClickedMobile(cat, false, i)
                          }
                        >
                          {cat.Title}
                        </div>
                      );
                    })}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <br />
          </Container>
        </Col>
        <Col md={10}>
          <br />
          <h1 className="pageTitle title-margin">{selectedCategory.Title}</h1>
          <br />
          <SearchBar
            lessons={[]}
            placeholder="Search This Category"
            setSearchValue={setSearchTitle}
          />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Accordion defaultActiveKey={defaultOpen} alwaysOpen>
              {Object.values(selectedCategory.SubCategories).map(
                (subCategory, i) => {
                  if (
                    subCategories[subCategory] === undefined ||
                    Object.keys(subCategories[subCategory].Lessons).length === 0
                  ) {
                    return <div key={i}></div>;
                  }
                  return (
                    <Accordion.Item eventKey={i.toString()} key={i}>
                      <Accordion.Header bsPrefix="accordion-title">
                        <h3 className="pageTitle title-margin">
                          {subCategory in subCategories
                            ? subCategories[subCategory].Title
                            : ""}
                        </h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {subCategories[subCategory] ? (
                            getFilteredLessons(
                              Object.values(subCategories[subCategory].Lessons)
                            )
                              .sort((a, b) => a.Title.localeCompare(b.Title))
                              .sort(
                                (a, b) =>
                                  Number("Sparkle" in b && b.Sparkle) -
                                  Number("Sparkle" in a && a.Sparkle)
                              )
                              .map((lesson, j) => {
                                if (lesson.Title === "empty") {
                                  return (
                                    <Col xs={12} key={i}>
                                      <h3 className="pageTitle">
                                        No Videos With This Title
                                      </h3>
                                    </Col>
                                  );
                                }
                                return (
                                  <Col sm={4} key={i * 100 + j}>
                                    <UnitLessonBox
                                      customClick={goToLesson}
                                      Lesson={lesson}
                                      notSubscribed={j !== 0 && !subscribed}
                                    ></UnitLessonBox>
                                  </Col>
                                );
                              })
                          ) : (
                            <p>Loading</p>
                          )}
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                }
              )}
            </Accordion>
          )}
          <br />
        </Col>
      </Row>
    </Container>
  );
}

export default UpdatedCategoryPage;
