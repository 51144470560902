import { useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Button, Stack } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  getImageUrl,
  getLessonById,
  getLessonNew,
  getMovementBreakById,
} from "../firebase";
import { useInView } from "react-intersection-observer";
import WorksheetBox from "./WorksheetBox";

function StructuredLessonContent(props) {
  const [lesson, setLesson] = useState({});
  const changeView = (inView, entry) => {
    props.elementChanging(props.order, inView);
  };
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: changeView,
  });
  useEffect(() => {
    if (props.isWorksheet) {
    } else {
      if (props.updated) {
        getLessonNew(props.id).then((data) => {
          if (data) {
            setLesson(data);
          } else {
            getMovementBreakById(props.id).then((data2) => {
              setLesson(data2);
            });
          }
        });
      } else {
        getLessonById(props.id).then((data) => {
          if (data) {
            setLesson(data);
          } else {
            getMovementBreakById(props.id).then((data2) => {
              setLesson(data2);
            });
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const loadResource = async (name) => {
    let url = await getImageUrl("resources/" + name);
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = name;
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Container fluid className="p-0" ref={ref}>
      {props.isWorksheet ? (
        <div>
          <h1
            className="pageTitle title-margin mb-3"
            id={"lesson-" + props.order}
          >
            Worksheets
          </h1>
          {props.edit ? (
            <Stack className="text-center m-3" direction="horizontal">
              <Button
                variant="danger"
                className="button-no-colour m-3"
                onClick={() => props.deleteLesson(props.order)}
              >
                Remove Worksheet
              </Button>
              <Button
                className="mainButton m-3 ms-auto"
                onClick={() => props.movePosition(props.order, -1)}
              >
                Move Up
              </Button>
              <Button
                className="mainButton m-3"
                onClick={() => props.movePosition(props.order, 1)}
              >
                Move Down
              </Button>
            </Stack>
          ) : (
            <></>
          )}
          <div className="structured-content-text-box">
            {props.worksheets?.map((worksheet, i) => {
              return (
                <div key={i}>
                  <WorksheetBox worksheet={worksheet} />
                  <br />
                </div>
              );
            })}
          </div>
          <br />
        </div>
      ) : (
        <div>
          <br />
          <h1 className="pageTitle title-margin" id={"lesson-" + props.order}>
            {`${props.order + 1}) ${lesson.Title}`}
          </h1>
          <br />
          <br />
          {props.edit ? (
            <Stack className="text-center m-3" direction="horizontal">
              <Button
                variant="danger"
                className="button-no-colour m-3"
                onClick={() => props.deleteLesson(props.order)}
              >
                Remove Lesson
              </Button>
              <Button
                className="mainButton m-3 ms-auto"
                onClick={() => props.movePosition(props.order, -1)}
              >
                Move Up
              </Button>
              <Button
                className="mainButton m-3"
                onClick={() => props.movePosition(props.order, 1)}
              >
                Move Down
              </Button>
            </Stack>
          ) : (
            <></>
          )}
          <div className="structured-lesson-video-box">
            <div className="structured-lesson-video">
              <div className="video-responsive-structured">
                {"UrlEmbeded" in lesson ? (
                  <iframe
                  key={props.id}
                    src={lesson.UrlEmbeded}
                    allowFullScreen
                    title="Embedded youtube"
                  />
                ) : (
                  <div width="853" height="480"></div>
                )}
              </div>
            </div>
          </div>

          <hr className="mx-0 px-0" />
          <div className="structured-content-text-box">
            <br />
            <h2 className="structured-content-heading">{lesson.Title}</h2>
            <br />
            <div className="structured-content-text">{lesson.Description}</div>
            <br />
            <h2 className="structured-content-heading">What will I need?</h2>
            <br />
            <ul>
              {lesson.Equipment
                ? lesson.Equipment.split(" & ").map((value, i) => {
                    return (
                      <li key={i} className="structured-content-text">
                        {value}
                      </li>
                    );
                  })
                : ""}
            </ul>
            <br />
            {"Resources" in lesson && lesson.Resources !== "" ? (
              <div>
                <h2 className="structured-content-heading">Resources</h2>
                <br />
                <ul>
                  {lesson.Resources
                    ? lesson.Resources.split("&").map((value, i) => {
                        return (
                          <li
                            className="grow headingColour"
                            key={i}
                            onClick={() => loadResource(value)}
                          >
                            <a role="button">{value}</a>
                          </li>
                        );
                      })
                    : ""}
                </ul>
                <br />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Container>
  );
}

export default StructuredLessonContent;
