import { useContext, useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import CategoryBox from "../../../components/CategoryBox";
import StrandCategoryBox from "../../../components/new-components/StrandCategoryBox";
import {
  deleteStrand,
  getStrandCategories,
  getStrandNew,
  updateStrand,
} from "../../../firebase";
import { UserContext } from "../../../providers/UserProvider";

function StrandDetailsPage() {
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  let { id } = useParams();

  const [strand, setStrand] = useState({});
  const [strandCopy, setStrandCopy] = useState({});
  const [categories, setCategories] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Daly Exercise+ | Strand Details";
    if ("UserName" in data) {
      setLoading(true);
      getStrandNew(id).then((res) => {
        setStrand(res);
        setStrandCopy(res);
        getStrandCategories(res.Categories).then((categories) => {
          if (categories.length > 0) {
            setCategories(categories.sort((a, b) => a.Order - b.Order));
          }

          setLoading(false);
        });
      });
    }
  }, [data]);

  const changeEdit = async (e) => {
    e.preventDefault();

    if (edit) {
      let updateSuccessful = await updateStrand(strandCopy);
      if (updateSuccessful) {
        setStrand({
          ...strandCopy,
        });
      } else {
        return;
      }
    }
    setEdit(!edit);
  };

  const cancelEdit = (e) => {
    e.preventDefault();
    setStrandCopy({
      ...strand,
    });
    setEdit(!edit);
  };

  const updateTitle = (val) => {
    setStrandCopy({
      ...strandCopy,
      Title: val,
    });
  };

  const updateColour = (val) => {
    console.log(val);
    setStrandCopy({
      ...strandCopy,
      ColourName: val,
    });
  };

  const updateImage = (val) => {
    setStrandCopy({
      ...strandCopy,
      ImageUri: val,
    });
  };

  const updateOrder = (val) => {
    setStrandCopy({
      ...strandCopy,
      StrandNumber: parseInt(val),
    });
  };

  const updateActive = (val) => {
    setStrandCopy({
      ...strandCopy,
      Active: val,
    });
  };

  const updateType = (val) => {
    setStrandCopy({
      ...strandCopy,
      Type: val,
    });
  };

  const deleteStrandClicked = async (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you wish to delete this strand?")) {
      let success = await deleteStrand(strand);
      if (success) {
        navigate("/content-management");
      }
    }
  };

  const goToCategory = (id) => {
    navigate(`/content-management/category/${id}`);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Strand Details</h1>
          <br />
          {loading ? (
            <div className="custom-loader"></div>
          ) : (
            <Form className="content-item-container">
              <Row>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Title</h3>
                    <Form.Control
                      value={strandCopy.Title}
                      disabled={!edit}
                      onChange={(event) => updateTitle(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Colour</h3>
                    <Form.Select
                      onChange={(event) => updateColour(event.target.value)}
                      disabled={!edit}
                    >
                      <option>{strandCopy.ColourName}</option>
                      <option value="TextPrimary">Black</option>
                      <option value="Green">Green</option>
                      <option value="Blue">Blue</option>
                      <option value="Purple">Purple</option>
                      <option value="Yellow">Yellow</option>
                      <option value="Red">Red</option>
                      <option value="Blue">Blue</option>
                      <option value="ActiveColour">Pink</option>
                      <option value="CalmingColour">Light Blue</option>
                      <option value="TikTokColour">Logo Colour</option>
                      <option value="ChristmasColour">
                        Christmas (Pinkish)
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Icon</h3>
                    <Form.Select
                      onChange={(event) => updateImage(event.target.value)}
                      disabled={!edit}
                    >
                      <option disabled>{strandCopy.ImageUri}</option>
                      <option value="icon_athletics">icon_athletics</option>
                      <option value="icon_alerting">icon_alerting</option>
                      <option value="icon_run">icon_athletics</option>
                      <option value="icon_brainbreak_tab">
                        icon_brainbreak_tab
                      </option>
                      <option value="icon_calming">icon_calming</option>
                      <option value="icon_christmas">icon_christmas</option>
                      <option value="icon_classroom_tab">
                        icon_classroom_tab
                      </option>
                      <option value="icon_dance">icon_dance</option>
                      <option value="icon_delete">icon_delete</option>
                      <option value="icon_easter">icon_easter</option>
                      <option value="icon_games">icon_games</option>
                      <option value="icon_graded">icon_graded</option>
                      <option value="icon_group">icon_group</option>
                      <option value="icon_gymnastics">icon_gymnastics</option>
                      <option value="icon_heart">icon_heart</option>
                      <option value="icon_help">icon_help</option>
                      <option value="icon_home">icon_home</option>
                      <option value="icon_homework_tab">
                        icon_homework_tab
                      </option>
                      <option value="icon_lessons_tab">icon_lessons_tab</option>
                      <option value="icon_logo">icon_logo</option>
                      <option value="icon_nc_games">icon_nc_games</option>
                      <option value="icon_new">icon_new</option>
                      <option value="icon_pencil">icon_pencil</option>
                      <option value="icon_random">icon_random</option>
                      <option value="icon_sports_day">icon_sports_day</option>
                      <option value="icon_thumbsup">icon_thumbsup</option>
                      <option value="icon_tiktok">icon_tiktok</option>
                      <option value="icon_timer">icon_timer</option>
                      <option value="icon_tool">icon_tool</option>
                      <option value="icon_warmup">icon_warmup</option>
                      <option value="icon_wave">icon_wave</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">ID</h3>
                    <Form.Control value={strandCopy.Guid} disabled />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Order</h3>
                    <Form.Control
                      type="number"
                      value={strandCopy.StrandNumber}
                      disabled={!edit}
                      onChange={(event) => updateOrder(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Is Active</h3>
                    <Form.Check
                      disabled={!edit}
                      defaultChecked={strandCopy.Active}
                      onChange={(event) => updateActive(event.target.checked)}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="content-item-box">
                    <h3 className="content-item-title">Type</h3>
                    <Form.Select
                      onChange={(event) => updateType(event.target.value)}
                      disabled={!edit}
                    >
                      <option value={strandCopy.Type}>{strandCopy.Type}</option>
                      <option value="Lesson">Lesson</option>
                      <option value="Movement Break">Movement Break</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={8}></Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button`}
                    onClick={(e) => changeEdit(e)}
                  >
                    {edit ? "Save" : "Edit"}
                  </button>
                </Col>
                <Col md={4}>
                  {edit ? (
                    <button
                      type="button"
                      className={`custom-card-button`}
                      onClick={(e) => cancelEdit(e)}
                    >
                      Cancel
                    </button>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    className={`custom-card-button custom-card-button-variant-delete`}
                    onClick={(e) => deleteStrandClicked(e)}
                  >
                    Delete
                  </button>
                </Col>
                <Col md={12}>
                  <StrandCategoryBox
                    title={strandCopy.Title}
                    icon={strandCopy.ImageUri}
                    color={strandCopy.ColourName}
                    content={strandCopy.Title}
                    click={() => ""}
                  ></StrandCategoryBox>
                </Col>
              </Row>
              <hr />
              <br />
              <h3 className="content-item-title">Categories</h3>
              <br />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Order</th>
                    <th># of subcategories</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, i) => {
                    return (
                      <tr key={i} onClick={() => goToCategory(category.Guid)}>
                        <th>{category.Title}</th>
                        <th>{category.Order}</th>
                        <th>
                          {category.SubCategories
                            ? category.SubCategories.length
                            : 0}
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Form>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default StrandDetailsPage;
