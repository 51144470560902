import { Button, Stack } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";

const ExerciseCustomBox = (props) => {
  const [gifUrl, setGifUrl] = useState("");
  const [bgColour, setBgColour] = useState("");

  const viewClicked = () => {
    props.PreviewExercise(props.exercise);
  };

  const selectedClicked = (e) => {
    e.preventDefault();
    if (e.target === e.currentTarget) {
      bgColour === "" ? setBgColour("warning") : setBgColour("");
      props.AddExercise(props.exercise);
    }
  };

  useEffect(() => {}, []);
  return (
    <Stack
      className={"p-1 grow bg-" + bgColour}
      direction="horizontal"
      style={{ backgroundColor: bgColour }}
      onClick={(e) => {
        selectedClicked(e);
      }}
    >
      <div>{props.exercise.Name}</div>
      <Button className="ms-auto align-middle" onClick={() => viewClicked()}>
        View
      </Button>
    </Stack>
  );
};

export default ExerciseCustomBox;
