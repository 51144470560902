import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Form,
  Table,
  Tabs,
  Tab,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import {
  getExactSubscription,
  moveToCheckout,
  openSubscriptionManagement,
} from "../../firebase";

import {
  assignAllSubscriptions,
  assignExactSubscriptionToTeacher,
  assignSubscriptionToTeacher,
  getSchoolAccessIDs,
  getSchoolDetails,
  getSchoolUnassignedCodeCount,
  getSchoolUsers,
  removeSubscriptionFromTeacher,
  removeTeacherFromSchool,
  updateSchoolAdminUsers,
  updateSchoolDetails,
} from "../../functions/schoolFunctions";
import {
  createSubscriptionPortalLink,
  getExactSchoolSubscription,
  getSchoolSubscription,
} from "../../functions/subscriptionFunctions";
import { UserContext } from "../../providers/UserProvider";

function ManageSchoolPage() {
  const [schoolName, setSchoolName] = useState("");
  const [schoolNameHelpText, setSchoolNameHelpText] = useState("");

  const [schoolRollNumber, setSchoolRollNumber] = useState("");
  const [schoolRollNumberHelpText, setSchoolRollNumberHelpText] = useState("");

  const [schoolTown, setSchoolTown] = useState("");
  const [schoolTownHelpText, setSchoolTownHelpText] = useState("");

  const [schoolCounty, setSchoolCounty] = useState("");
  const [schoolCountyHelpText, setCountyTownHelpText] = useState("");

  const [schoolPostCode, setSchoolPostCode] = useState("");

  const [schoolCountry, setSchoolCountry] = useState("Ireland");

  const [schoolDetails, setSchoolDetails] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});

  const [disableEditDetails, setDisableEditDetails] = useState(true);

  const [schoolAccounts, setSchoolAccounts] = useState([]);

  const [unassignedSubscriptions, setUnassignedSubscriptions] = useState(0);

  const [refresh, setRefresh] = useState(false);

  const { data, data2 } = useContext(UserContext);
  const [adminAccess, setAdminAccess] = useState(false);

  const [numOfTeachers, setNumOfTeachers] = useState(1);
  const [numOfTeachersHelpText, setNumOfTeachersHelpText] = useState("");

  const [frequencyText, setFrequecyText] = useState("per year");

  const [subscriptionType, setSubscriptionType] = useState("74.99");
  const [subscriptionTypeText, setSubscriptionTypeText] = useState("");

  const [manageSubscriptionLoading, setManageSubscriptionLoading] =
    useState(false);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const navigate = useNavigate();

  let { tab } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    document.title = "Daly Exercise+ | Manage School";
    if (data2 && "SchoolID" in data2) {
      getSchoolDetails(data2.SchoolID).then((res) => {
        setSchoolDetails(res);
        setSchoolName(res.Name);
        setSchoolRollNumber(res.RollNumber);
        setSchoolTown(res.Town);
        setSchoolCounty(res.County);
        setSchoolCountry(res.Country);
        setSchoolPostCode(res.PostCode);
        getExactSchoolSubscription(res.SubscriptionReference).then(
          (subscription) => {
            setSubscriptionDetails(subscription);
          }
        );
        if (res.AdminID.includes(data.Guid)) {
          setAdminAccess(true);
          setLoading(false);
        }
      });

      getSchoolUsers(data2.SchoolID).then((res) => {
        setSchoolAccounts(res);
      });

      getSchoolUnassignedCodeCount(data2.SchoolID).then((res) => {
        setUnassignedSubscriptions(res);
      });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, refresh, data2]);

  const changeSubscriptionType = async (e) => {
    setSubscriptionType(e.target.value);
    if (e.target.value === "9.99") {
      setFrequecyText("per month");
    } else {
      setFrequecyText("per year");
    }
  };

  const changeNumOfTeachers = async (e) => {
    if (
      e.target.value === "" ||
      isNaN(e.target.value) ||
      parseInt(e.target.value) <= 0
    ) {
      setNumOfTeachers("1");
    } else {
      setNumOfTeachers(e.target.value);
    }

    if (!(e.target.value === "1" || e.target.value === "")) {
      setFrequecyText("per year");
      setSubscriptionType("74.99");
    }
  };

  const manageSubscriptionClicked = async () => {
    setManageSubscriptionLoading(true);
    if (subscriptionDetails.status !== "Not Subscribed") {
      if (!subscriptionDetails.isFree) {
        const url = await createSubscriptionPortalLink(data2.SchoolID);
        window.open(url, "_blank");
      }

      setManageSubscriptionLoading(false);
    } else {
      if (subscriptionType === "74.99") {
        var itemPrice = "price_1JdeKYFlmgYLxX3G48LVhml5";
        // var itemPrice = "price_1Jb4QhFlmgYLxX3GXnc4mvNs";
      } else if (subscriptionType === "9.99") {
        var itemPrice = "price_1Je3VKFlmgYLxX3GpCbfOmbW";
        // var itemPrice = "price_1Jb4QhFlmgYLxX3G2hMAfrbl";
      } else {
        setManageSubscriptionLoading(false);
        return false;
      }
      moveToCheckout(itemPrice, data.Guid, numOfTeachers);
    }
  };

  const editSchoolDetailsClicked = async () => {
    if (!disableEditDetails) {
      await saveSchoolDetails();
    }
    setDisableEditDetails(!disableEditDetails);
  };

  const importEmailsClicked = async () => {
    navigate("/manage-school/import-emails");
  };

  const goToTab = async (destination) => {
    navigate(`/manage-school/${destination}`);
  };

  const saveSchoolDetails = async () => {
    let details = {
      Name: schoolName,
      RollNumber: schoolRollNumber,
      Town: schoolTown,
      County: schoolCounty,
      Country: schoolCountry,
      PostCode: schoolPostCode,
    };

    await updateSchoolDetails(data2.SchoolID, details);
  };

  const filterByEmail = () => {
    const sorted = [...schoolAccounts.sort((a, b) => a.Email > b.Email)];
    setSchoolAccounts(sorted);
  };

  const filterBySubscription = () => {
    const sorted = [
      ...schoolAccounts.sort((a, b) => a.HasAccess <= b.HasAccess),
    ];
    setSchoolAccounts(sorted);
  };

  const filterByRole = () => {
    const sorted = [
      ...schoolAccounts.sort(
        (a, b) =>
          schoolDetails.AdminID.includes(a.Guid) <=
          schoolDetails.AdminID.includes(b.Guid)
      ),
    ];
    setSchoolAccounts(sorted);
  };

  const removeSubscription = async (teacherID) => {
    let res = await removeSubscriptionFromTeacher(data2.SchoolID, teacherID);

    if (!res) {
      window.alert("Something went wrong, please try again.");
      return;
    }

    setRefresh(!refresh);
  };

  const removeAllSubscriptions = async () => {
    let confirm = window.confirm(
      "Are you sure you wish to unassign all user subscriptions? These can always be assigned again."
    );
    if (!confirm) {
      return;
    }
    setLoading(true)
    let accountPromises = [];
    schoolAccounts.forEach((account) => {
      accountPromises.push(
        removeSubscriptionFromTeacher(data2.SchoolID, account.Guid)
      );
    });
    let res = await Promise.all(accountPromises);

    if (!res.every((v) => v === true)) {
      window.alert("Something went wrong, please try again.");
      return;
    }
    setRefresh(!refresh);
    setLoading(false)
  };

  const assignSubscription = async (teacherID) => {
    let res = await assignSubscriptionToTeacher(data2.SchoolID, teacherID);

    if (!res) {
      window.alert("Something went wrong, please try again.");
      return;
    }

    setRefresh(!refresh);
  };

  const autoAssignSubscriptions = async () => {
    let confirm = window.confirm(
      "Are you sure you wish to automatically assign subscriptions?"
    );
    if (!confirm) {
      return;
    }
    setLoading(true)
    await assignAllSubscriptions(data2.SchoolID)
    setRefresh(!refresh);
    
  };

  const removeTeacher = async (teacherID) => {
    let confirm = window.confirm(
      "Are you sure you wish to remove this teacher from your school?"
    );
    if (!confirm) {
      return;
    }
    let res = await removeTeacherFromSchool(data2.SchoolID, teacherID);

    if (!res) {
      window.alert("Something went wrong, please try again.");
      return;
    }

    setRefresh(!refresh);
  };

  const removeAllTeachers = async () => {
    let confirm = window.confirm(
      "Are you sure you wish to remove all accounts from your school?"
    );
    if (!confirm) {
      return;
    }
    let accountPromises = [];
    schoolAccounts.forEach((account) => {
      if (!schoolDetails.AdminID.includes(account.Guid)) {
        accountPromises.push(
          removeTeacherFromSchool(data2.SchoolID, account.Guid)
        );
      }
    });
    let res = await Promise.all(accountPromises);

    if (!res.every((v) => v === true)) {
      window.alert("Something went wrong, please try again.");
      return;
    }
    setRefresh(!refresh);
  };

  const changeAdminAccessLevel = async (teacherID) => {
    let adminUsers = [...schoolDetails.AdminID];
    let access = true;

    if (teacherID == data.Guid) {
      if (adminUsers.length == 1) {
        window.alert("You cannot remove all admins from a school.");
        return;
      }
      let confirm = window.confirm(
        "Are you sure you wish to remove your own admin access? To get it back, another admin must change your access level."
      );
      if (!confirm) {
        return;
      }
      access = false;
      adminUsers = adminUsers.filter((e) => e !== teacherID);
    } else {
      if (adminUsers.includes(teacherID)) {
        let confirm = window.confirm(
          "Are you sure you wish to remove this teacher's admin access?"
        );
        if (!confirm) {
          return;
        }
        adminUsers = adminUsers.filter((e) => e !== teacherID);
      } else {
        let confirm = window.confirm(
          "Are you sure you wish to give this teacher admin access?"
        );
        if (!confirm) {
          return;
        }
        adminUsers.push(teacherID);
      }
    }
    let res = await updateSchoolAdminUsers(
      data2.SchoolID,
      adminUsers,
      schoolDetails.StripeCustomerRef
    );

    if (!res) {
      window.alert("Something went wrong, please try again.");
      return;
    }
    setAdminAccess(access);
    setRefresh(!refresh);
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">My School</h1>
          <br />
          {loading ? (
            <div className="custom-loader" />
          ) : (
            <div className="">
              <Tabs
                activeKey={tab}
                className="manage-school-tabs"
                fill
                variant="pills"
                onSelect={(key) => goToTab(key)}
              >
                <Tab eventKey="school" title="School Details">
                  <Form>
                    <div className="white-box">
                      <Stack direction="horizontal">
                        <h3 className="title-margin page-title">
                          School Details
                        </h3>
                      </Stack>
                    </div>

                    <Row>
                      <Col md={8}>
                        <div className="white-box equal-height">
                          <Row>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="schoolName"
                              >
                                <Form.Label className="form-label-text">
                                  School Name
                                </Form.Label>
                                <Form.Control
                                  disabled={disableEditDetails}
                                  value={schoolName}
                                  required
                                  placeholder="School Name"
                                  aria-describedby="schoolNameHelpBlock"
                                  onChange={(event) =>
                                    setSchoolName(event.target.value)
                                  }
                                />
                                <Form.Text
                                  id="schoolNameHelpBlock"
                                  muted
                                  className="text-danger"
                                >
                                  {schoolNameHelpText}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="rollNumber"
                              >
                                <Form.Label className="form-label-text">
                                  Roll Number
                                </Form.Label>
                                <Form.Control
                                  disabled={disableEditDetails}
                                  value={schoolRollNumber}
                                  placeholder="Roll Number"
                                  aria-describedby="rollNumberHelpBlock"
                                  onChange={(event) =>
                                    setSchoolRollNumber(event.target.value)
                                  }
                                />
                                <Form.Text
                                  id="rollNumberHelpBlock"
                                  muted
                                  className="text-danger"
                                >
                                  {schoolRollNumberHelpText}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="schoolTown"
                              >
                                <Form.Label className="form-label-text">
                                  Town
                                </Form.Label>
                                <Form.Control
                                  disabled={disableEditDetails}
                                  value={schoolTown}
                                  placeholder="Town"
                                  aria-describedby="schoolTownHelpBlock"
                                  onChange={(event) =>
                                    setSchoolTown(event.target.value)
                                  }
                                />
                                <Form.Text
                                  id="schoolTownHelpBlock"
                                  muted
                                  className="text-danger"
                                >
                                  {schoolTownHelpText}
                                </Form.Text>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="schoolCounty"
                              >
                                <Form.Label className="form-label-text">
                                  County
                                </Form.Label>
                                <Form.Control
                                  disabled={disableEditDetails}
                                  value={schoolCounty}
                                  placeholder="County"
                                  aria-describedby="schoolCountyHelpBlock"
                                  onChange={(event) =>
                                    setSchoolCounty(event.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group className="mb-3" controlId="country">
                                <Form.Label className="form-label-text">
                                  Country
                                </Form.Label>
                                <Form.Select
                                  disabled={disableEditDetails}
                                  defaultValue={schoolCountry}
                                  onChange={(event) =>
                                    setSchoolCountry(event.target.value)
                                  }
                                >
                                  <option value="Ireland">Ireland</option>
                                  <option value="Afghanistan">
                                    Afghanistan
                                  </option>
                                  <option value="Åland Islands">
                                    Åland Islands
                                  </option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">
                                    American Samoa
                                  </option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antarctica">Antarctica</option>
                                  <option value="Antigua and Barbuda">
                                    Antigua and Barbuda
                                  </option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bosnia and Herzegovina">
                                    Bosnia and Herzegovina
                                  </option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Bouvet Island">
                                    Bouvet Island
                                  </option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Territory">
                                    British Indian Ocean Territory
                                  </option>
                                  <option value="Brunei Darussalam">
                                    Brunei Darussalam
                                  </option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">
                                    Burkina Faso
                                  </option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">
                                    Cayman Islands
                                  </option>
                                  <option value="Central African Republic">
                                    Central African Republic
                                  </option>
                                  <option value="Chad">Chad</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">
                                    Christmas Island
                                  </option>
                                  <option value="Cocos (Keeling) Islands">
                                    Cocos (Keeling) Islands
                                  </option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Congo, The Democratic Republic of The">
                                    Congo, The Democratic Republic of The
                                  </option>
                                  <option value="Cook Islands">
                                    Cook Islands
                                  </option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote D'ivoire">
                                    Cote D'ivoire
                                  </option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">
                                    Czech Republic
                                  </option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">
                                    Dominican Republic
                                  </option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">
                                    El Salvador
                                  </option>
                                  <option value="Equatorial Guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands (Malvinas)">
                                    Falkland Islands (Malvinas)
                                  </option>
                                  <option value="Faroe Islands">
                                    Faroe Islands
                                  </option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">
                                    French Guiana
                                  </option>
                                  <option value="French Polynesia">
                                    French Polynesia
                                  </option>
                                  <option value="French Southern Territories">
                                    French Southern Territories
                                  </option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guernsey">Guernsey</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guinea-bissau">
                                    Guinea-bissau
                                  </option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Heard Island and Mcdonald Islands">
                                    Heard Island and Mcdonald Islands
                                  </option>
                                  <option value="Holy See (Vatican City State)">
                                    Holy See (Vatican City State)
                                  </option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="India">India</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="Iran, Islamic Republic of">
                                    Iran, Islamic Republic of
                                  </option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Isle of Man">
                                    Isle of Man
                                  </option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jersey">Jersey</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea, Democratic People's Republic of">
                                    Korea, Democratic People's Republic of
                                  </option>
                                  <option value="Korea, Republic of">
                                    Korea, Republic of
                                  </option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Lao People's Democratic Republic">
                                    Lao People's Democratic Republic
                                  </option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libyan Arab Jamahiriya">
                                    Libyan Arab Jamahiriya
                                  </option>
                                  <option value="Liechtenstein">
                                    Liechtenstein
                                  </option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macao">Macao</option>
                                  <option value="Macedonia, The Former Yugoslav Republic of">
                                    Macedonia, The Former Yugoslav Republic of
                                  </option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">
                                    Marshall Islands
                                  </option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Micronesia, Federated States of">
                                    Micronesia, Federated States of
                                  </option>
                                  <option value="Moldova, Republic of">
                                    Moldova, Republic of
                                  </option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montenegro">Montenegro</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Namibia">Namibia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherlands">
                                    Netherlands
                                  </option>
                                  <option value="Netherlands Antilles">
                                    Netherlands Antilles
                                  </option>
                                  <option value="New Caledonia">
                                    New Caledonia
                                  </option>
                                  <option value="New Zealand">
                                    New Zealand
                                  </option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">
                                    Norfolk Island
                                  </option>
                                  <option value="Northern Mariana Islands">
                                    Northern Mariana Islands
                                  </option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau">Palau</option>
                                  <option value="Palestinian Territory, Occupied">
                                    Palestinian Territory, Occupied
                                  </option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">
                                    Papua New Guinea
                                  </option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Philippines">
                                    Philippines
                                  </option>
                                  <option value="Pitcairn">Pitcairn</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">
                                    Puerto Rico
                                  </option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russian Federation">
                                    Russian Federation
                                  </option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="Saint Helena">
                                    Saint Helena
                                  </option>
                                  <option value="Saint Kitts and Nevis">
                                    Saint Kitts and Nevis
                                  </option>
                                  <option value="Saint Lucia">
                                    Saint Lucia
                                  </option>
                                  <option value="Saint Pierre and Miquelon">
                                    Saint Pierre and Miquelon
                                  </option>
                                  <option value="Saint Vincent and The Grenadines">
                                    Saint Vincent and The Grenadines
                                  </option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome and Principe">
                                    Sao Tome and Principe
                                  </option>
                                  <option value="Saudi Arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Serbia">Serbia</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">
                                    Sierra Leone
                                  </option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">
                                    Solomon Islands
                                  </option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">
                                    South Africa
                                  </option>
                                  <option value="South Georgia and The South Sandwich Islands">
                                    South Georgia and The South Sandwich Islands
                                  </option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Svalbard and Jan Mayen">
                                    Svalbard and Jan Mayen
                                  </option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">
                                    Switzerland
                                  </option>
                                  <option value="Syrian Arab Republic">
                                    Syrian Arab Republic
                                  </option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania, United Republic of">
                                    Tanzania, United Republic of
                                  </option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Timor-leste">
                                    Timor-leste
                                  </option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad and Tobago">
                                    Trinidad and Tobago
                                  </option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">
                                    Turkmenistan
                                  </option>
                                  <option value="Turks and Caicos Islands">
                                    Turks and Caicos Islands
                                  </option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Emirates">
                                    United Arab Emirates
                                  </option>
                                  <option value="United Kingdom">
                                    United Kingdom
                                  </option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  <option value="United States Minor Outlying Islands">
                                    United States Minor Outlying Islands
                                  </option>
                                  <option value="Uruguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Viet Nam">Viet Nam</option>
                                  <option value="Virgin Islands, British">
                                    Virgin Islands, British
                                  </option>
                                  <option value="Virgin Islands, U.S.">
                                    Virgin Islands, U.S.
                                  </option>
                                  <option value="Wallis and Futuna">
                                    Wallis and Futuna
                                  </option>
                                  <option value="Western Sahara">
                                    Western Sahara
                                  </option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md={4}>
                              <Form.Group
                                className="mb-3"
                                controlId="schoolTown"
                              >
                                <Form.Label className="form-label-text">
                                  Postcode
                                </Form.Label>
                                <Form.Control
                                  disabled={disableEditDetails}
                                  value={schoolPostCode}
                                  placeholder="Postcode"
                                  aria-describedby="schoolTownHelpBlock"
                                  onChange={(event) =>
                                    setSchoolPostCode(event.target.value)
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col md={4}>
                        <div className="white-box equal-height">
                          <h3 className="title-margin page-title text-center">
                            Actions
                          </h3>
                          <button
                            disabled={!adminAccess}
                            className="custom-button-big w-100 mt-3"
                            type="button"
                            onClick={() => editSchoolDetailsClicked()}
                          >
                            {disableEditDetails
                              ? "Edit Details"
                              : "Save Details"}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Tab>

                {adminAccess ? (
                  <Tab eventKey="subscription" title="Subscription Details">
                    <div className="white-box">
                      <Stack direction="horizontal">
                        <h3 className="title-margin page-title">
                          Subscription Details
                        </h3>
                        {subscriptionDetails.status === "Not Subscribed" ? (
                          <h3 className="ms-auto">Not Subscribed!</h3>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </div>

                    <Row>
                      <Col md={8}>
                        <div className="white-box equal-height">
                          {subscriptionDetails.status === "Not Subscribed" ? (
                            <Form>
                              <h3 className="ms-auto">
                                Subscribe for a 6 week free trial!
                              </h3>
                              <hr />
                              <Row>
                                <Col md={6}>
                                  <Form.Group
                                    className="mb-3"
                                    controlId="numOfTeachers"
                                  >
                                    <Form.Label className="form-label-text">
                                      Number of teachers: {numOfTeachers}
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="1"
                                      aria-describedby="numOfTeachersHelpBlock"
                                      onChange={(event) =>
                                        changeNumOfTeachers(event)
                                      }
                                    />
                                    <Form.Text
                                      id="numOfTeachersHelpBlock"
                                      muted
                                    >
                                      {numOfTeachersHelpText}
                                    </Form.Text>
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label className="form-label-text">
                                      Subscription Type
                                    </Form.Label>
                                    <Form.Select
                                      onChange={(event) =>
                                        changeSubscriptionType(event)
                                      }
                                    >
                                      {parseInt(numOfTeachers) === 1 ? (
                                        <>
                                          <option value={74.99}>
                                            €74.99 yearly subscription
                                          </option>
                                          <option value={9.99}>
                                            €9.99 monthly subscription
                                          </option>
                                        </>
                                      ) : (
                                        <option value={74.99}>
                                          €74.99 yearly subscription
                                        </option>
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col md={12}>
                                  <Form.Group className="mb-3" controlId="">
                                    <Form.Label className="form-label-text">
                                      Total Price (after 6 week trial)
                                    </Form.Label>
                                    <Form.Control
                                      disabled
                                      placeholder={
                                        "€" +
                                        (
                                          parseFloat(numOfTeachers) *
                                          parseFloat(subscriptionType)
                                        ).toFixed(2) +
                                        " " +
                                        frequencyText
                                      }
                                      aria-describedby="areaHelpBlock"
                                      onChange={(event) =>
                                        setNumOfTeachers(event.target.value)
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form>
                          ) : (
                            <div>
                              <Stack direction="horizontal" className="m-3">
                                <div className="form-label-text">
                                  Subscription Count
                                </div>
                                <div className="ms-auto">
                                  {subscriptionDetails.quantity}
                                </div>
                              </Stack>
                              <hr />
                              <Stack direction="horizontal" className="m-3">
                                <div className="form-label-text">
                                  Subscription Type
                                </div>
                                <div className="ms-auto">
                                  {subscriptionDetails.isFree
                                    ? "Paid By Invoice"
                                    : subscriptionDetails.status === "trialing"
                                    ? "Trial"
                                    : "Active"}
                                </div>
                              </Stack>
                              <hr />
                              <Stack direction="horizontal" className="m-3">
                                <div className="form-label-text">
                                  Subscription Interval
                                </div>
                                <div className="ms-auto">
                                  {subscriptionDetails.interval === "month"
                                    ? "Monthly"
                                    : "Annual"}
                                </div>
                              </Stack>
                              <hr />
                              <Stack direction="horizontal" className="m-3">
                                <div className="form-label-text">
                                  Next Payment Date
                                </div>
                                <div className="ms-auto">
                                  {subscriptionDetails.nextPaymentDue
                                    ?.toDate()
                                    .toDateString(options)}
                                </div>
                              </Stack>
                              <hr />
                              <Stack direction="horizontal" className="m-3">
                                <div className="form-label-text">
                                  Amount Due
                                </div>
                                <div className="ms-auto">
                                  {"€" +
                                    (
                                      (subscriptionDetails.quantity *
                                        subscriptionDetails.price) /
                                      100
                                    ).toString()}
                                </div>
                              </Stack>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="white-box equal-height">
                          <h3 className="title-margin page-title text-center">
                            Actions
                          </h3>
                          {manageSubscriptionLoading ? (
                            <div>
                              <div className="custom-loader"></div>
                              <div className="text-center">
                                This may take a few seconds...
                              </div>
                            </div>
                          ) : (
                            <button
                              className="custom-button-big w-100 mt-3"
                              onClick={() => manageSubscriptionClicked()}
                            >
                              {subscriptionDetails.status === "Not Subscribed"
                                ? "Subscribe Now!"
                                : "Manage Subscription"}
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                ) : (
                  <></>
                )}

                <Tab eventKey="accounts" title="Teachers">
                  <div className="white-box">
                    <Stack direction="horizontal">
                      <h3 className="title-margin page-title">Teachers</h3>
                      <h5 className="ms-auto text-end">
                        Unassigned Subscriptions: {unassignedSubscriptions}
                      </h5>
                    </Stack>
                  </div>
                  <Row>
                    <Col md={8}>
                      <div className="white-box equal-height">
                        <Table striped bordered>
                          <thead>
                            <tr>
                              <th
                                className="table-sort"
                                onClick={() => filterByRole()}
                              >
                                Role
                              </th>
                              <th
                                className="table-sort"
                                onClick={() => filterByEmail()}
                              >
                                Email
                              </th>
                              <th
                                className="table-sort"
                                onClick={() => filterBySubscription()}
                              >
                                Subscribed
                              </th>
                              <th className="text-end">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {schoolAccounts.map((account, i) => {
                              return (
                                <tr key={i}>
                                  <th>
                                    {schoolDetails.AdminID.includes(
                                      account.Guid
                                    )
                                      ? "Admin"
                                      : "Teacher"}
                                  </th>
                                  <th>{account.Email}</th>
                                  <th>
                                    {account.HasAccess ? "True" : "False"}
                                  </th>
                                  <th>
                                    {adminAccess ? (
                                      <Dropdown drop="start">
                                        <Dropdown.Toggle
                                          as="button"
                                          className="custom-button custom-button-variant-2"
                                        >
                                          Manage
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              event.preventDefault();
                                              if (account.HasAccess) {
                                                return removeSubscription(
                                                  account.Guid
                                                );
                                              } else if (
                                                unassignedSubscriptions > 0
                                              ) {
                                                return assignSubscription(
                                                  account.Guid
                                                );
                                              } else {
                                              }
                                            }}
                                          >
                                            {account.HasAccess
                                              ? "Unassign Subscription"
                                              : unassignedSubscriptions > 0
                                              ? "Assign Subscription"
                                              : "No Subscriptions Available"}
                                          </Dropdown.Item>
                                          <Dropdown.Divider />
                                          {schoolDetails.AdminID.includes(
                                            account.Guid
                                          ) ? (
                                            <></>
                                          ) : (
                                            <>
                                              
                                              <Dropdown.Item
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  if (
                                                    schoolDetails.AdminID.includes(
                                                      account.Guid
                                                    )
                                                  ) {
                                                    return;
                                                  } else {
                                                    return removeTeacher(
                                                      account.Guid
                                                    );
                                                  }
                                                }}
                                              >
                                                "Remove Account"
                                              </Dropdown.Item>
                                              <Dropdown.Divider />
                                            </>
                                          )}
                                          <Dropdown.Item
                                            onClick={(event) => {
                                              event.preventDefault();
                                              changeAdminAccessLevel(
                                                account.Guid
                                              );
                                            }}
                                          >
                                            {schoolDetails.AdminID.includes(
                                              account.Guid
                                            )
                                              ? "Remove Admin Access"
                                              : "Give Admin Access"}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      <></>
                                    )}
                                  </th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="white-box equal-height">
                        <h3 className="title-margin page-title text-center">
                          Actions
                        </h3>
                        <button
                          disabled={!adminAccess}
                          className="custom-button-big  w-100 mt-3"
                          onClick={() => importEmailsClicked()}
                        >
                          Add Teacher
                        </button>
                        <button
                          disabled={!adminAccess}
                          className="custom-button-big  w-100 mt-3"
                          onClick={() => autoAssignSubscriptions()}
                        >
                          Assign Subscriptions
                        </button>

                        <br />
                        <br />
                        <br />
                        <br />

                        <button
                          disabled={!adminAccess}
                          className="custom-button-big custom-button-variant-bad w-100 mt-3"
                          onClick={() => removeAllTeachers()}
                        >
                          Remove All Accounts
                        </button>
                        <button
                          disabled={!adminAccess}
                          className="custom-button-big custom-button-variant-bad w-100 mt-3"
                          onClick={() => removeAllSubscriptions()}
                        >
                          Remove Subscriptions
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <br />
              <br /> <br />
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default ManageSchoolPage;
