import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import {
  addEmailsToAdmins,
  addSubscriptionTypeToAdmins,
  getAdminsDetailsForAnalytics,
  getNews,
  getTeacherDetailsFromAdminList,
  uploadContentToDatabase,
  uploadFileToStorage,
} from "../firebase";
import { UserContext } from "../providers/UserProvider";
import NewsBox from "../components/NewsBox";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import WorksheetBox from "../components/WorksheetBox";
import { useNavigate } from "react-router";
import CustomCard from "../components/generic/CustomCard";

const AnalyticsDashboard = () => {
  const navigate = useNavigate();
  const [paidAdminAccounts, setPaidAdminAccounts] = useState([]);
  const [couponAdminAccounts, setCouponAdminAccounts] = useState([]);
  const [trialAdminAccounts, setTrialAdminAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { data } = useContext(UserContext);

  useEffect(() => {
    document.title = "Daly Exercise+ | Glen Admin";
    if (data.Guid !== "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2") {
      navigate("/");
    }
    setLoading(true);
    getAdminsDetailsForAnalytics().then((res) => {
      setPaidAdminAccounts(res.paid);
      setCouponAdminAccounts(res.coupon);
      setTrialAdminAccounts(res.trial);
      setLoading(false);
    });

    // addSubscriptionTypeToAdmins()
  }, [data]);

  const adminToTotalAccounts = (admins) => {
    let total = 0;
    admins.forEach((admin) => {
      if ("InviteCodes" in admin) {
        total += admin.InviteCodes.length;
      } else {
        console.log(admin);
      }
    });

    return total;
  };

  const manageCouponAccounts = () => {
    navigate("/manage/coupon");
  };

  const downloadAdminDataAsCSV = (data) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "Email,Status,IsFree,Codes,IsAdmin,SubscriptionDate,FirstName,SecondName,ContactNumber,Role,SchoolName,SchoolRoleNumber,SchoolTown,SchoolCounty,SchoolCountry,CouponCode\r\n";

    data.forEach((row) => {
      if (row !== null) {
        csvContent +=
          row.Email +
          "," +
          row.Status +
          "," +
          row.HasCouponCode +
          "," +
          ("InviteCodes" in row ? row.InviteCodes.length : 0) +
          "," +
          true +
          "," +
          (row.Created ? row.Created.replaceAll(",", " ") : "") +
          "," +
          (row.FirstName ? row.FirstName.replaceAll(",", " ") : "") +
          "," +
          (row.SecondName ? row.SecondName.replaceAll(",", " ") : "") +
          "," +
          (row.ContactNumber ? row.ContactNumber.replaceAll(",", " ") : "") +
          "," +
          (row.Roll ? row.Roll.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolRoleNumber
            ? row.SchoolRoleNumber.replaceAll(",", " ")
            : "") +
          "," +
          (row.SchoolTown ? row.SchoolTown.replaceAll(",", " ") : "") +
          "," +
          (row.County ? row.County.replaceAll(",", " ") : "") +
          "," +
          (row.Country ? row.Country.replaceAll(",", " ") : "") +
          "," +
          (row.CouponCode ? row.CouponCode.replaceAll(",", " ") : "") +
          "\r\n";
      }
    });
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "ippn_account_emails.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const downloadTeacherDataAsCSV = async (adminData) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent +=
      "Email,Status,IsFree,Codes,IsAdmin,SubscriptionDate,FirstName,SecondName,ContactNumber,Role,SchoolName,SchoolRoleNumber,SchoolTown,SchoolCounty,SchoolCountry,CouponCode\r\n";
    let data = await getTeacherDetailsFromAdminList(adminData);
    data.forEach((row) => {
      if (row !== null) {
        csvContent +=
          row.Email +
          "," +
          row.Status +
          "," +
          row.HasCouponCode +
          "," +
          ("InviteCodes" in row ? row.InviteCodes.length : 0) +
          "," +
          row.IsAdmin +
          "," +
          (row.Created ? row.Created.replaceAll(",", " ") : "") +
          "," +
          (row.FirstName ? row.FirstName.replaceAll(",", " ") : "") +
          "," +
          (row.SecondName ? row.SecondName.replaceAll(",", " ") : "") +
          "," +
          (row.ContactNumber ? row.ContactNumber.replaceAll(",", " ") : "") +
          "," +
          (row.Roll ? row.Roll.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolName ? row.SchoolName.replaceAll(",", " ") : "") +
          "," +
          (row.SchoolRoleNumber
            ? row.SchoolRoleNumber.replaceAll(",", " ")
            : "") +
          "," +
          (row.SchoolTown ? row.SchoolTown.replaceAll(",", " ") : "") +
          "," +
          (row.County ? row.County.replaceAll(",", " ") : "") +
          "," +
          (row.Country ? row.Country.replaceAll(",", " ") : "") +
          "," +
          (row.CouponCode ? row.CouponCode.replaceAll(",", " ") : "") +
          "\r\n";
      }
    });
    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "iaccount_emails.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Analytics</h1>
          <br />
          <Row>
            <Col md={4}>
              <CustomCard
                style={1}
                loading={loading}
                title={"Paid Admins"}
                text={paidAdminAccounts.length}
                buttonText={"Download Details"}
                callToAction={() => downloadAdminDataAsCSV(paidAdminAccounts)}
              />
            </Col>

            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"IPPN Admins"}
                text={couponAdminAccounts.length}
                buttonText={"Download Details"}
                callToAction={() => downloadAdminDataAsCSV(couponAdminAccounts)}
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={3}
                loading={loading}
                title={"Trial Admins"}
                text={trialAdminAccounts.length}
                buttonText={"Download Details"}
                callToAction={() => downloadAdminDataAsCSV(trialAdminAccounts)}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={4}>
              <CustomCard
                style={1}
                loading={loading}
                title={"Paid Teachers"}
                text={adminToTotalAccounts(paidAdminAccounts)}
                buttonText={"Download Details"}
                callToAction={() => downloadTeacherDataAsCSV(paidAdminAccounts)}
              />
            </Col>

            <Col md={4}>
              <CustomCard
                style={2}
                loading={loading}
                title={"IPPN Teachers"}
                text={adminToTotalAccounts(couponAdminAccounts)}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(couponAdminAccounts)
                }
              />
            </Col>
            <Col md={4}>
              <CustomCard
                style={3}
                loading={loading}
                title={"Trial Teachers"}
                text={adminToTotalAccounts(trialAdminAccounts)}
                buttonText={"Download Details"}
                callToAction={() =>
                  downloadTeacherDataAsCSV(trialAdminAccounts)
                }
              />
            </Col>
          </Row>
          <hr />
          <Stack>
            <button
              className={`custom-card-button custom-card-button-variant-1`}
              onClick={() => manageCouponAccounts()}
            >
              Manage Coupon Accounts
            </button>
            <button
              className={`custom-card-button custom-card-button-variant-1`}
              onClick={() => manageCouponAccounts()}
            >
              Manage Coupon Accounts
            </button>
          </Stack>
        </Container>
      </div>
    </Container>
  );
};

export default AnalyticsDashboard;
