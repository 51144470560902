import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import StrandCategoryBox from "../../components/new-components/StrandCategoryBox";
import WorkoutBox from "../../components/new-components/WorkoutBox";
import StudentBox from "../../components/StudentBox";
import { getClassroomStudents } from "../../functions/classroomFunctions";
import { getClassroomWorkouts } from "../../functions/workoutFunctions";
import { UserContext } from "../../providers/UserProvider";

function SavedWorkoutsPage() {
  const [className, setClassName] = useState("");
  const [workouts, setWorkouts] = useState([]);
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";

    if ("Guid" in data2) {
      getClassroomWorkouts(data2.Guid).then((res) => {
        setWorkouts(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const goToWorkoutsType = (type) => {
    navigate(type);
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Saved Workouts</h1>
          <div className="white-box">
            <Row>
              {workouts.map((workout, i) => {
                return (
                  <Col md={3} key={i}>
                    <WorkoutBox Workout={workout} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </Container>
  );
}

export default SavedWorkoutsPage;
