import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import AccountPropertyBox from "../../components/AccountPropertyBox";
import { UserContext } from "../../providers/UserProvider";
import { uploadUserData } from "../../functions/accountFunctions";
import Loading from "../../components/Loading";
import { uploadAllExercisesToFirestore } from "../../functions/databaseBatchFunctions";

function MyAccountPage() {
  const [saving, setSaving] = useState(false);
  const [yearGroup, setYearGroup] = useState(0);
  const [email, setEmail] = useState("");

  const [editAccount, setEditAccount] = useState(false);

  const { data2 } = useContext(UserContext);

  const [firstName, setFirstName] = useState("");

  const [secondName, setSecondName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [userRole, setUserRole] = useState("");

  const [classroomName, setClassroomName] = useState("");
  useEffect(() => {
    document.title = "Daly Exercise+ | Account";

    if ("Guid" in data2) {
      setFirstName(data2.FirstName ? data2.FirstName : "");
      setSecondName(data2.Surname ? data2.Surname : "");
      setPhoneNumber(data2.ContactNumber ? data2.ContactNumber : "");
      setUserRole(data2.Role ? data2.Role : "");
      setClassroomName(data2.ClassroomName ? data2.classroomName : "");
      setEmail(data2.Email);
      let year = data2.Year ? data2.Year : 0;
      setYearGroup(year);
    }
  }, [data2]);

  const onEditAccountClicked = async () => {
    if (editAccount) {
      setSaving(true);

      let account = {
        FirstName: firstName,
        Surname: secondName,
        ContactNumber: phoneNumber,
        Role: userRole,
        ClassroomName: classroomName,
        Year: yearGroup,
      };
      await uploadUserData(data2.Guid, account);

      setSaving(false);
    }

    setEditAccount(!editAccount);
  };

  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">My Account</h1>
          <br />
          <Form>
            <div className="white-box">
              <Stack direction="horizontal">
                <h3 className="title-margin page-title">Email</h3>
                <h4 className="ms-auto">{email}</h4>
              </Stack>
            </div>

            <Row>
              <Col md={8}>
                <div className="white-box equal-height">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="firstName">
                        <Form.Label className="form-label-text">
                          First Name
                        </Form.Label>
                        <Form.Control
                          disabled={!editAccount}
                          required
                          placeholder="First Name"
                          aria-describedby="firstNameHelpBlock"
                          onChange={(event) => setFirstName(event.target.value)}
                          value={firstName}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="secondName">
                        <Form.Label className="form-label-text">
                          Second Name
                        </Form.Label>
                        <Form.Control
                          disabled={!editAccount}
                          value={secondName}
                          required
                          placeholder="Second Name"
                          aria-describedby="secondNameHelpBlock"
                          onChange={(event) =>
                            setSecondName(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="contactNumber">
                        <Form.Label className="form-label-text">
                          Contact Number
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>+353</InputGroup.Text>
                          <Form.Control
                            disabled={!editAccount}
                            type="tel"
                            value={phoneNumber}
                            required
                            placeholder=""
                            aria-describedby="phoneNumberHelpBlock"
                            onChange={(event) =>
                              setPhoneNumber(event.target.value)
                            }
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="userRole">
                        <Form.Label className="form-label-text">
                          Role
                        </Form.Label>
                        <Form.Control
                          disabled={!editAccount}
                          required
                          value={userRole}
                          placeholder="Principal, Teacher etc"
                          aria-describedby="userRoleHelpBlock"
                          onChange={(event) => setUserRole(event.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="userRole">
                        <Form.Label className="form-label-text">
                          Classroom Name
                        </Form.Label>
                        <Form.Control
                          disabled={!editAccount}
                          value={classroomName}
                          placeholder="My Classroom"
                          aria-describedby="userRoleHelpBlock"
                          onChange={(event) =>
                            setClassroomName(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="userRole">
                        <Form.Label className="form-label-text">
                          Content Year Group
                        </Form.Label>
                        <Form.Select
                          disabled={!editAccount}
                          value={yearGroup}
                          onChange={(event) => setYearGroup(event.target.value)}
                        >
                          <option value={0}>Junior or Senior</option>
                          <option value={1}>1st or 2nd Class</option>
                          <option value={2}>3rd or 4th Class</option>
                          <option value={3}>5th or 6th Class</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={4}>
                <div className="white-box equal-height">
                  <h3 className="title-margin page-title text-center">
                    Actions
                  </h3>
                  {saving ? (
                    <div className="custom-loader" />
                  ) : (
                    <button
                      onClick={onEditAccountClicked}
                      className="custom-button-big w-100 mt-3"
                      type="button"
                    >
                      {editAccount ? "Save" : "Edit"}
                    </button>
                  )}
                  {/* <button
                      className="custom-button-big custom-button-variant-good w-100 mt-3 mb-3"
                    >
                      Help
                    </button>
                    <button
                      className="custom-button-big custom-button-variant-bad w-100 mt-3"
                    >
                      Delete Account
                    </button> */}
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </Container>
  );
}

export default MyAccountPage;
