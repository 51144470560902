import { useContext, useEffect, useState } from "react";
import { Col, Container, Row, Stack, Tab, Table, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router";
import StudentBox from "../../components/StudentBox";
import { getClassroomStudents } from "../../functions/classroomFunctions";
import { UserContext } from "../../providers/UserProvider";

function ClassroomPage() {
  const [className, setClassName] = useState("");
  const [students, setStudents] = useState([]);
  const { data2 } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Classroom";

    if ("Guid" in data2) {
      setClassName(data2.ClassroomName);
      getClassroomStudents(data2.Guid).then((res) => {
        setStudents(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data2]);

  const getStudentAvatar = (avatar) => {
    console.log(avatar);
    return `/avatars/${avatar}`;
  };

  const addStudentClicked = () => {
    navigate("add");
  };
  return (
    <Container fluid>
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">My Classroom</h1>
          <br />
          <Tabs
            // activeKey={tab}
            className="manage-school-tabs"
            fill
            variant="pills"
            // onSelect={(key) => goToTab(key)}
          >
            <Tab eventKey="manage" title="Classroom Details">
              <div className="white-box">
                <Stack direction="horizontal">
                  <h3 className="title-margin page-title">Classroom Details</h3>
                  <button
                    className="custom-button-big custom-button-variant-good ms-auto"
                    onClick={addStudentClicked}
                  >
                    Add Students
                  </button>
                </Stack>
                <br />
                <Row>
                  {students.map((student, i) => {
                    return (
                      <Col xs={6} sm={4} md={3} xl={2} xxl={2} key={i}>
                        <StudentBox Student={student}></StudentBox>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </Tab>
            <Tab eventKey="fms" title="FMS"></Tab>
          </Tabs>
        </Container>
      </div>
    </Container>
  );
}

export default ClassroomPage;
