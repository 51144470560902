import { Navbar, Container, Nav, NavDropdown, Stack } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import logo from "../high_res_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { auth, verifyInviteCode } from "../firebase";
import { signOut } from "firebase/auth";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import cjflogo from "../cjf_logo.jpg";

const MyNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const { data, setYear, data2 } = useContext(UserContext);
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  const [yearText, setYearText] = useState("Loading...");
  useEffect(() => {
    if (data2 && "Guid" in data2) {
      if ("Year" in data2) {
        if (data2.Year === 0) {
          setYearText("Junior & Senior");
        } else if (data2.Year === 1) {
          setYearText("1st & 2nd");
        } else if (data2.Year === 2) {
          setYearText("3rd & 4th");
        } else {
          setYearText("5th & 6th");
        }
      } else {
        setYearText("Loading...");
      }
    }
  }, [data2]);
  const logout = (e) => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  const changeYear = async (newYear) => {
    if (newYear === 0) {
      setYearText("Junior & Senior");
    } else if (newYear === 1) {
      setYearText("1st & 2nd");
    } else if (newYear === 2) {
      setYearText("3rd & 4th");
    } else {
      setYearText("5th & 6th");
    }
    setYear(newYear);
  };

  return (
    <Navbar bg="white" expand="md" collapseOnSelect>
      <Container fluid className="navbar-container">
        <Navbar.Brand onClick={() => goBack()} className="" eventKey={0}>
          <span className="material-symbols-outlined return_arrow">
            arrow_back
          </span>
        </Navbar.Brand>
        <Navbar.Brand as={Link} to="/" eventKey={1}>
          <img
            alt=""
            src={logo}
            width="120"
            height="50"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-end my-auto text-center"
        >
          <Navbar.Brand className="me-auto">
            <NavDropdown
              title={yearText}
              bsPrefix="year-dropdown dropdown-toggle nav-link"
            >
              <NavDropdown.Item onClick={() => changeYear(0)}>
                Junior & Senior
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeYear(1)}>
                1st & 2nd
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeYear(2)}>
                3rd & 4th
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => changeYear(3)}>
                5th & 6th
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Brand>

          {/* <Navbar.Brand as={Link} to="https://www.cjfallon.ie/">
          <img
            alt=""
            src={cjflogo}
            width="96"
            height="40"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand> */}

          <Nav className="justify-content-end">
            <Nav.Link as={Link} to="/structured-lessons" eventKey={2}>
              <div className="grow-big d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  title="Structured Lessons"
                  src="/icons/icon_games.png"
                  width="50"
                  height="50"
                  style={{
                    marginTop: 5,
                    marginBottom: 0,
                    marginRight: -0,
                    marginLeft: -0,
                  }}
                  className=""
                ></img>
                <div className="tabbar-text-computer">Structured Lessons</div>
              </div>
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/classroom" eventKey={3}>
              <div className="grow-big d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  title="Classroom"
                  src="/icons/icon_classroom_tab.png"
                  width="100"
                  height="100"
                  style={{
                    marginTop: -25,
                    marginBottom: -30,
                    marginRight: -20,
                    marginLeft: -20,
                  }}
                  className=""
                ></img>
                <div className="tabbar-text-computer">Classroom</div>
              </div>
            </Nav.Link> */}

            <Nav.Link as={Link} to="/schedule" eventKey={4}>
              <div className="grow-big  d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  alt="Schedule"
                  title="Schedule"
                  src="/icons/icon_schedule_tab.png"
                  width="45"
                  height="45"
                  style={{
                    marginTop: 8,
                  }}
                ></img>
                <div className="tabbar-text-computer">Schedule</div>
              </div>
            </Nav.Link>

            <Nav.Link as={Link} to="/lessons" eventKey={5}>
              <div className="grow-big  d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  alt="Lessons"
                  title="Lessons"
                  src="/icons/icon_lessons_tab.png"
                  width="65"
                  height="65"
                  style={{
                    marginTop: -5,
                    marginBottom: -10,
                    marginRight: -5,
                    marginLeft: -5,
                  }}
                ></img>
                <div className="tabbar-text-computer">PE Strands</div>
              </div>
            </Nav.Link>
            <Nav.Link as={Link} to="/movement-breaks" eventKey={6}>
              <div className="grow-big  d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  title="Movement Breaks"
                  alt="Movement Breaks"
                  src="/icons/icon_brainbreak_tab.png"
                  width="45"
                  height="45"
                  style={{
                    marginTop: 8,
                  }}
                ></img>
                <div className="tabbar-text-computer">Movement Breaks</div>
              </div>
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/workouts" eventKey={7}>
              <div className="grow-big  d-flex flex-column align-items-center justify-content-between h-100 navbar-item">
                <img
                  title="Homework"
                  src="/icons/icon_homework_tab.png"
                  width="75"
                  height="75"
                  style={{
                    marginTop: -10,
                    marginBottom: -10,
                    marginRight: -10,
                    marginLeft: -15,
                  }}
                ></img>
                <div className="tabbar-text-computer">Classroom Workouts</div>
              </div>
            </Nav.Link> */}
            <div>
              <NavDropdown
                title="More"
                drop="start"
                className={subscribed ? "" : "highlight-subscription-dropdown"}
                id="basic-nav-dropdown"
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                }}
              >
                <NavDropdown.Item as={Link} to="/account" eventKey={8}>
                  My Account
                </NavDropdown.Item>
                {data2 && data2.SchoolID.length > 0 ? (
                  <NavDropdown.Item
                    as={Link}
                    to="/manage-school/school"
                    eventKey={9}
                  >
                    My School
                  </NavDropdown.Item>
                ) : (
                  <></>
                )}

                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to="/login"
                  onClick={logout}
                  eventKey={10}
                >
                  Logout
                </NavDropdown.Item>

                {data?.Guid === "j74ZyWsM3Yg8snPpsjyfKZXb6BJ2" ? (
                  <div>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      as={Link}
                      to="/content-management"
                      eventKey={11}
                    >
                      Content Management
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/analytics" eventKey={12}>
                      Analytics
                    </NavDropdown.Item>
                  </div>
                ) : (
                  <></>
                )}
              </NavDropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
