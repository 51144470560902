import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Stack,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useNavigate } from "react-router-dom";
import AccountPropertyBox from "../../components/AccountPropertyBox";
import CustomModal from "../../components/generic/CustomModal";
import ScheduleItem from "../../components/ScheduleItem";
import { addTeacherToSchool } from "../../functions/schoolFunctions";
import { UserContext } from "../../providers/UserProvider";

function ImportEmailsPage() {
  const { data, data2 } = useContext(UserContext);

  const [emailList, setEmailList] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [uploadingData, setUploadingData] = useState(false);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Daly Exercise+ | Manage School";
    if ("IsAdmin" in data2 && data2.IsAdmin && "SchoolID" in data2) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const placeholder = `email1@gmail.com
email2@gmail.com
email3@gmail.com
...

  `;

  const confirmEmailList = async () => {
    let emails = emailList.split("\n");
    if (emails.length > 0) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmModal = async () => {
    const emails = emailList.split("\n");
    const schoolID = data2.SchoolID;
    let functionPromises = [];
    closeModal()
    setUploadingData(true);
    emails.forEach((email) => {
      functionPromises.push(
        addTeacherToSchool(email, schoolID).then((res) => {
          return { Success: res, Email: email };
        })
      );
    });

    const result = await Promise.all(functionPromises);

    if (result.every((v) => v.Success === true)) {
      navigate(-1);
      return;
    }

    let errorMessage = "Error uploading these emails:\n";
    result.forEach((res) => {
      if (!res.Success) {
        errorMessage += res.Email + " \n";
      }
    });

    setUploadingData(false);

    window.alert(errorMessage);
  };

  const formatEmailList = () => {
    let comp = (
      <div>
        {emailList.split("\n").map((email, i) => {
          return <div key={i}>{email}</div>;
        })}
      </div>
    );

    return comp;
  };

  return (
    <Container fluid>
      <CustomModal
        onClose={closeModal}
        onConfirm={confirmModal}
        show={showModal}
        title="Please Confirm"
        text="Are these emails correct?"
        subtext={formatEmailList()}
      />
      <div className="dashboard-body">
        <Container>
          <h1 className="pageTitle title-margin">Import Emails</h1>
          <br />
          {uploadingData ? (
            <div className="custom-loader"></div>
          ) : (
            <div className="school-container">
              <div className="header-text">Copy and paste your teacher's details</div>
              <br />
              <div className="emphasis-text">
                Paste the email addresses you wish to add to your school. Each
                email should be separated by a new line.
              </div>
              <br />
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Control
                    as="textarea"
                    rows={10}
                    placeholder={placeholder}
                    onChange={(event) => setEmailList(event.target.value)}
                  />
                </Form.Group>
              </Form>
              <button
                className="custom-button-big float-end"
                type="button"
                onClick={() => confirmEmailList()}
              >
                Import
              </button>
              <br />
              <br />
              <br />
            </div>
          )}
        </Container>
      </div>
    </Container>
  );
}

export default ImportEmailsPage;
