import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions } from "../firebase";
import { getEmptySchool } from "../models/school";
import { updateSubscriptionAdminUsers } from "./subscriptionFunctions";

function getRandomInt(max) {
  return Math.floor(Math.random() * max) + 1;
}

export async function getClassroomStudents(teacherID) {
  try {
    const q = collection(firestoreDb, `users/${teacherID}/students`);
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function addStudentToClassroom(teacherID, studentDetails) {
  try {
    const id = uuidv4().toString();
    const avatar = "avatar" + getRandomInt(84).toString() + ".png";
    studentDetails = {
      ...studentDetails,
      Guid: id,
      Avatar: avatar,
    };
    const q = doc(firestoreDb, `users/${teacherID}/students/${id}`);
    await setDoc(q, studentDetails);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}
