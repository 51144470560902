import { useContext, useEffect, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import CategoryBox from "../components/CategoryBox";
import SearchBarLink from "../components/SearchBarLink";
import { getEventStrandsByYear, getStrandsByYear } from "../firebase";
import { UserContext } from "../providers/UserProvider";

function Lessons() {
  const [strandsText, setStrandsText] = useState("");
  const [strands, setStrands] = useState([]);
  const { data } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Daly Exercise+ | Lessons";
    if ("UserName" in data) {
      switch (parseInt(data.Year)) {
        case 0:
          setStrandsText("Junior & Senior");
          break;
        case 1:
          setStrandsText("1st & 2nd");
          break;
        case 2:
          setStrandsText("3rd & 4th");
          break;
        case 3:
          setStrandsText("5th & 6th");
          break;
        default:
          setStrandsText("ERROR");
      }

      getStrandsByYear(data.Year).then((result) => {
        getEventStrandsByYear(data.Year).then((result2) => {
          setStrands(result2.concat(result));
        });
      });
    }
  }, [data]);
  return (
    <Container className="full-page-length">
      <Stack direction="horizontal" gap={1} className="my-auto">
        <h1 className="pageTitle title-margin me-auto ms-auto">Lessons</h1>
        {/* <img
            alt=""
            src={"/icons/icon_help.png"}
            width={80}
            height={80}
            className="m-1 ms-auto"
          /> */}
      </Stack>

      <hr />

      <h2 className="pageTitle sm-margin-bottom">Strands for {strandsText}</h2>
      {strands?.map((strand) => {
        return <CategoryBox strand={strand} key={strand.Guid}></CategoryBox>;
      })}
    </Container>
  );
}

export default Lessons;
