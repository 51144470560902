import { useContext } from "react";
import { UserContext } from "./providers/UserProvider";
import { Routes, Route, Navigate } from "react-router-dom";
import LoggedOutNav from "./components/LoggedOutNav";
import MyNavbar from "./components/MyNavbar";
import Classroom from "./pages/Classroom";
import Login from "./pages/Login";
import About from "./pages/About";
import Account from "./pages/Account";
import StudentPage from "./pages/StudentPage";
import Lessons from "./pages/Lessons";
import UnitPage from "./pages/UnitPage";
import LessonPage from "./pages/LessonPage";
import MovementBreaks from "./pages/MovementBreaks";
import MovementBreakList from "./pages/MovementBreakList";
import MovementBreakPage from "./pages/MovementBreakPage";
import Schedule from "./pages/SchedulePage";
import AddToSchedulePage from "./pages/AddToSchedulePage";
import Dashboard from "./pages/Dashboard";
import AdminPage from "./pages/AdminPage";
import InviteCodesPage from "./pages/InviteCodesPage";
import MyFooter from "./components/MyFooter";
import RegisterPage from "./pages/RegisterPage";
import ClassroomDetailsPage from "./pages/ClassroomDetailsPage";
import HomeworkBasePage from "./pages/HomeworkBasePage";
import BuySubscriptionPage from "./pages/BuySubscriptionPage";
import ClasscodePage from "./pages/ClasscodePage";
import AddStudentPage from "./pages/AddStudentPage";
import HomeworkRandomPage from "./pages/HomeworkRandomPage";
import HomeworkCustomisePage from "./pages/HomeworkCustomisePage";
import HomeworkRecommendationsPage from "./pages/HomeworkRecommendationsPage";
import HomeworkViewPage from "./pages/HomeworkViewPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import HomeworkTestPage from "./pages/HomeworkTestPage";
import SchoolRegisterPage from "./pages/SchoolRegisterPage";
import EditNewsPage from "./pages/EditNewsPage";
import StructuredLessonPage from "./pages/StructuredLessonPage";
import StructuredLessonListPage from "./pages/StructuredLessonListPage";
import GlenAdminPage from "./pages/GlenAdminPage";
import PricingPage from "./pages/PricingPage";
import DataSafetyPage from "./pages/DataSafetyPage";
import LoggedInFooter from "./components/LoggedInFooter";
import NewSubscriptionPage from "./pages/NewSubscriptionPage";
import AnalyticsDashboard from "./pages/AnalyticsDashboard";
import ManageCouponAccountsPage from "./pages/ManageCouponAccountsPage";
import UpdatedStrandsPage from "./pages/updated-pages/UpdatedStrandsPage";
import UpdatedCategoryPage from "./pages/updated-pages/UpdatedCategoryPage";
import ContentManagementPage from "./pages/content-management/ContentManagementPage";
import StrandDetailsPage from "./pages/content-management/strand-pages/StrandDetailsPage";
import AddStrandPage from "./pages/content-management/strand-pages/AddStrandPage";
import CategoryDetailsPage from "./pages/content-management/category-pages/CategoryDetailsPage";
import AddCategoryPage from "./pages/content-management/category-pages/AddCategoryPage";
import SubCategoryDetailsPage from "./pages/content-management/subcategory-pages/SubCategoryDetailsPage";
import AddSubCategoryPage from "./pages/content-management/subcategory-pages/AddSubCategoryPage";
import LessonDetailsPage from "./pages/content-management/lesson-pages/LessonDetailsPage";
import AddLessonPage from "./pages/content-management/lesson-pages/AddLessonPage";
import UpdatedLessonPage from "./pages/updated-pages/UpdatedLessonPage";
import UpdatedMovementBreakListPage from "./pages/updated-pages/UpdatedMovementBreakListPage";
import UpdatedAddToSchedulePage from "./pages/updated-pages/UpdatedAddToSchedulePage";
import UpdatedSchedulePage from "./pages/updated-pages/UpdatedSchedulePage";
import UpdatedStructuredLessonPage from "./pages/updated-pages/UpdatedStructuredLessonPage";
import ScrollToTop from "./components/new-components/ScrollToTop";
import ManageSchoolPage from "./pages/school-pages/ManageSchoolPage";
import ImportEmailsPage from "./pages/school-pages/ImportEmailsPage";
import RegisterDetailsPage from "./pages/register-pages/RegisterDetailsPage";
import { NoSchoolPage } from "./components/page-wrappers/NoSchoolPage";
import MyAccountPage from "./pages/account-pages/MyAccountPage";
import ClassroomPage from "./pages/classroom-pages/ClassroomPage";
import ImportStudentsPage from "./pages/classroom-pages/ImportStudentsPage";
import ClassroomWorkoutsPage from "./pages/workouts-pages/ClassroomWorkoutsPage";
import RandomWorkoutPage from "./pages/workouts-pages/RandomWorkoutPage";
import SavedWorkoutsPage from "./pages/workouts-pages/SavedWorkoutsPage";
import SetupPage from "./pages/register-pages/SetupPage";
function Application() {
  const { data } = useContext(UserContext);

  return (
    <div className="bg-light-1">
      {data ? (
        <>
          <MyNavbar />
          <Routes className="">
            <Route
              path="/"
              element={
                <NoSchoolPage>
                  <Dashboard />
                </NoSchoolPage>
              }
            />

            <Route
              path="/lessons"
              element={
                <NoSchoolPage>
                  <UpdatedStrandsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/lessons/:id"
              element={
                <NoSchoolPage>
                  <UpdatedCategoryPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/lessons/:categoryId/:id"
              element={
                <NoSchoolPage>
                  <UpdatedLessonPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/lessons/:categoryId/:id/add"
              element={
                <NoSchoolPage>
                  <UpdatedAddToSchedulePage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/movement-breaks"
              element={
                <NoSchoolPage>
                  <UpdatedMovementBreakListPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/movement-breaks/:id"
              element={
                <NoSchoolPage>
                  <UpdatedCategoryPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/movement-breaks/:categoryId/:id"
              element={
                <NoSchoolPage>
                  <UpdatedLessonPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/movement-breaks/:categoryId/:id/add"
              element={
                <NoSchoolPage>
                  <UpdatedAddToSchedulePage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management"
              element={
                <NoSchoolPage>
                  <ContentManagementPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management/strand/:id"
              element={
                <NoSchoolPage>
                  <StrandDetailsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management/strand/add"
              element={
                <NoSchoolPage>
                  <AddStrandPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/content-management/category/:id"
              element={
                <NoSchoolPage>
                  <CategoryDetailsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management/category/add"
              element={
                <NoSchoolPage>
                  <AddCategoryPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management/subcategory/:id"
              element={
                <NoSchoolPage>
                  <SubCategoryDetailsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/content-management/subcategory/add"
              element={
                <NoSchoolPage>
                  <AddSubCategoryPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/content-management/lesson/:id"
              element={
                <NoSchoolPage>
                  <LessonDetailsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/content-management/lesson/add"
              element={
                <NoSchoolPage>
                  <AddLessonPage />
                </NoSchoolPage>
              }
            />

            {/* <Route path="/*" element={<Navigate to="/" replace />} /> */}
            <Route
              path="/admin"
              element={
                <NoSchoolPage>
                  <AdminPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/admin/invite-codes"
              element={
                <NoSchoolPage>
                  <InviteCodesPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/manage-school/:tab"
              element={
                <NoSchoolPage>
                  <ManageSchoolPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/manage-school/import-emails"
              element={
                <NoSchoolPage>
                  <ImportEmailsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/classroom"
              element={
                <NoSchoolPage>
                  <ClassroomPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/classroom/add"
              element={
                <NoSchoolPage>
                  <ImportStudentsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/classroom/classcode"
              element={
                <NoSchoolPage>
                  <ClasscodePage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/classroom/classcode/add-student"
              element={
                <NoSchoolPage>
                  <AddStudentPage />
                </NoSchoolPage>
              }
            />
            {/* <Route path="/lessons" element={<UpdatedStrandsPage />} /> */}
            <Route
              path="/account"
              element={
                <NoSchoolPage>
                  <MyAccountPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/schedule"
              element={
                <NoSchoolPage>
                  <UpdatedSchedulePage />
                </NoSchoolPage>
              }
            />
            {/* <Route path="/movement-breaks" element={<MovementBreaks />} /> */}
            {/* <Route
              path="/movement-breaks/category/:id"
              element={<MovementBreakList />}
            />
            <Route
              path="/movement-breaks/movement-break/:id"
              element={<MovementBreakPage />}
            />
            <Route
              path="/movement-breaks/movement-break/:id/add"
              element={<AddToSchedulePage Type="Movement Break" />}
            /> */}

            <Route
              path="/student/:id"
              element={
                <NoSchoolPage>
                  <StudentPage />
                </NoSchoolPage>
              }
            />
            {/* <Route path="/lessons/unit/:id" element={<UnitPage />} />
            <Route path="/lessons/lesson/:id" element={<LessonPage />} /> */}
            {/* <Route
              path="/lessons/lesson/:id/add"
              element={<AddToSchedulePage Type="Lesson" />}
            /> */}

            <Route
              path="/class-details"
              element={
                <NoSchoolPage>
                  <ClassroomDetailsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/subscription"
              element={
                <NoSchoolPage>
                  <RegisterDetailsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/register"
              element={
                <NoSchoolPage>
                  <RegisterDetailsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/workouts"
              element={
                <NoSchoolPage>
                  <ClassroomWorkoutsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/workouts/random"
              element={
                <NoSchoolPage>
                  <RandomWorkoutPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/workouts/saved"
              element={
                <NoSchoolPage>
                  <SavedWorkoutsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/homework"
              element={
                <NoSchoolPage>
                  <HomeworkBasePage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/homework/random"
              element={
                <NoSchoolPage>
                  <HomeworkRandomPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/homework/custom"
              element={
                <NoSchoolPage>
                  <HomeworkCustomisePage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/homework/recommend"
              element={
                <NoSchoolPage>
                  <HomeworkRecommendationsPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/homework/assigned/:id"
              element={
                <NoSchoolPage>
                  <HomeworkViewPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/homework/assigned/:id/view"
              element={
                <NoSchoolPage>
                  <HomeworkTestPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/structured-lessons"
              element={
                <NoSchoolPage>
                  <StructuredLessonListPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/structured-lessons/:id"
              element={
                <NoSchoolPage>
                  <StructuredLessonPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/updated-structured-lessons/:id"
              element={
                <NoSchoolPage>
                  <UpdatedStructuredLessonPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/news/edit"
              element={
                <NoSchoolPage>
                  <EditNewsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/add-content"
              element={
                <NoSchoolPage>
                  <GlenAdminPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/data-safety"
              element={
                <NoSchoolPage>
                  <DataSafetyPage />
                </NoSchoolPage>
              }
            />
            <Route
              path="/analytics"
              element={
                <NoSchoolPage>
                  <AnalyticsDashboard />
                </NoSchoolPage>
              }
            />
            <Route
              path="/manage/coupon"
              element={
                <NoSchoolPage>
                  <ManageCouponAccountsPage />
                </NoSchoolPage>
              }
            />

            <Route
              path="/setup"
              element={
                <NoSchoolPage>
                  <SetupPage />
                </NoSchoolPage>
              }
            />
          </Routes>
          {/* <br />
          <div className="footer-divider"/>
          <br /> */}
          <LoggedInFooter />
          {/* <MyFooter /> */}
        </>
      ) : (
        <>
          <LoggedOutNav />
          <Routes>
            <Route path="/" element={<About />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<Login NoRedirect={true} />} />
            <Route path="/register/school" element={<SchoolRegisterPage />} />
            <Route path="/register/teacher" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/data-safety" element={<Login NoRedirect={true} />} />
          </Routes>
        </>
      )}
    </div>
  );
}

export default Application;
