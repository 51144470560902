import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

import { v4 as uuidv4 } from "uuid";

import { httpsCallable } from "firebase/functions";

import { firestoreDb, functions, storage } from "../firebase";
import { getEmptySchool } from "../models/school";
import { updateSubscriptionAdminUsers } from "./subscriptionFunctions";
import { getDownloadURL, ref as sRef } from "firebase/storage";

export async function getClassroomWorkouts(teacherID) {
  try {
    const q = collection(firestoreDb, `users/${teacherID}/workouts`);
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function addWorkoutToClassroom(teacherID, workout) {
  try {
    const id = uuidv4().toString();
    workout = {
      ...workout,
      Guid: id,
    };
    const q = doc(firestoreDb, `users/${teacherID}/workouts/${id}`);
    await setDoc(q, workout);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export async function getRandomExercisesByYear(year, numOfRandom) {
  try {
    const q = query(
      collection(firestoreDb, `exercises`),
      where("Year", "==", year)
    );
    let snapshot = await getDocs(q);
    let docs = snapshot.docs.map((val) => {
      return val.data();
    });
    return docs.sort((a, b) => 0.5 - Math.random()).slice(0, numOfRandom);
  } catch (e) {
    console.log(e);
    return [];
  }
}

export async function getExerciseUrl(name) {
  try {
    return await getDownloadURL(sRef(storage, name));
  } catch (e) {
    console.log(e);
    return "";
  }
}
